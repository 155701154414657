import React from "react";
import { Row, Col, Container } from "reactstrap";
import tablegroup from "../../assets/images/tablegroup-p-1080.png";
import smilinggroup from "../../assets/images/smilinggroup-p-1080.png";
import walkinggroup from "../../assets/images/walkinggroup.png";
import funds4 from "../../assets/images/funds4-p-500.png";
import united4 from "../../assets/images/united4.png";
import united3 from "../../assets/images/united3.png";
import united2 from "../../assets/images/united2-p-500.png";
import united1 from "../../assets/images/united1-p-500.png";
import scholarship2 from "../../assets/images/scholarship2-p-1080.png";
import scholarship1 from "../../assets/images/scholarship1.png";
import funds1 from "../../assets/images/funds1-p-500.png";
import funds from "../../assets/images/11funds.png";
import funds3 from "../../assets/images/funds3-p-500.png";
import d_funds2 from "../../assets/images/d_funds2.png";
import f_need1 from "../../assets/images/f_need1-p-1080.png";
import a_giving1 from "../../assets/images/a_giving1-p-1080.png";

const Howyoucanhelp = () => {
  return (
    <Container>
      <Row className="justify-content-center line_height">
        <Col
          sm="12"
          md={{
            size: 10,
          }}
        >
          <h1 className="mt-4 mb-4 fs-1">How You Can Help</h1>
        </Col>
        <Col
          sm="12"
          md={{
            size: 10,
          }}
          className="my-2"
        >
          <p>
            <a href="https://www.paypal.com/donate?hosted_button_id=4H6TK5BDLZJNL">
              A "Greatest Need" contribution (preferred) helps FCL countries
              respond to immediate needs.
            </a>
          </p>
          <p>
            <a href="https://www.paypal.com/donate?hosted_button_id=4H6TK5BDLZJNL">
              Please earmark your quarterly, biannual or annual contribution to
              Full-Circle Learning and send it through our fiscal agent, the
              Mahmoudi Foundation, a partner serving the Full-Circle Learning
              students and countries.
            </a>
          </p>
          <p>
            You can also act by learning this coherent system for education as
            transformation, to share "best practices" with colleagues or
            students. To schedule a professional development course for
            educators, go to the following link:&nbsp;
            <a href="https://educator.fullcirclelearning.org/">
              https://educator.fullcirclelearning.org
            </a>
          </p>
          <div>
            <img
              src={tablegroup}
              loading="lazy"
              sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 991px) 728px, 940px"
              alt="Teachers consult together at a table working through plans."
            />
          </div>
        </Col>
        <Col
          sm="12"
          md={{
            size: 10,
          }}
          className="my-2"
        >
          <img
            src={smilinggroup}
            loading="lazy"
            sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 991px) 728px, 940px"
            alt="Students gather for a outside on green grass."
          />
          <img
            src={walkinggroup}
            loading="lazy"
            sizes="(max-width: 479px) 94vw, (max-width: 649px) 95vw, 617px"
            alt="A group of students walk together carrying a banner."
          />
        </Col>
        <Col
          sm="12"
          md={{
            size: 10,
          }}
          className="my-2"
        >
          <div style={{ columnCount: "2" }}>
            <img
              src={funds4}
              loading="lazy"
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 47vw, (max-width: 991px) 356px, 462px"
              alt="A group of students at a service project."
            />
            <img
              src={united4}
              loading="lazy"
              alt="A woman presents a painting while talking."
            />
            <img
              src={united3}
              loading="lazy"
              alt="Two students in a vehicle looking out."
            />
            <img
              src={united2}
              loading="lazy"
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 47vw, (max-width: 991px) 356px, 462px"
              alt="A group gathers for."
            />
            <img
              src={united1}
              loading="lazy"
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 47vw, (max-width: 991px) 356px, 462px"
              alt="A student begins to climb a ladder assisted by her Teacher."
            />
          </div>
        </Col>
        <Col
          sm="12"
          md={{
            size: 10,
          }}
          className="my-2"
        >
          <div>
            <img
              src={scholarship2}
              loading="lazy"
              sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 991px) 728px, 940px"
              alt="A student is sharing knowledge with the rest of the className seated outside."
            />
            <img
              src={scholarship1}
              loading="lazy"
              sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 991px) 728px, 788px"
              alt="A student presents a paper."
            />
          </div>
        </Col>
        <Col
          sm="12"
          md={{
            size: 10,
          }}
          className="my-2"
        >
          <div style={{ columnCount: "2" }}>
            <img
              src={funds1}
              loading="lazy"
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 47vw, (max-width: 991px) 356px, 462px"
              alt="A group stands behind piles of dry food."
            />
            <img
              src={funds}
              loading="lazy"
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 47vw, (max-width: 991px) 356px, 462px"
              alt="A group of students at a service project."
            />
            <img
              src={funds3}
              loading="lazy"
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 47vw, (max-width: 991px) 356px, 462px"
              alt="A boy stands outside his home."
            />
            <img
              src={d_funds2}
              loading="lazy"
              alt="Two students sharing a project."
            />
          </div>
        </Col>
        <Col
          sm="12"
          md={{
            size: 10,
          }}
          className="my-2"
        >
          <div>
            <img
              src={f_need1}
              loading="lazy"
              sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 991px) 728px, 940px"
              alt="Students greet the camera from afar surrounded by green farm fields."
            />
          </div>
        </Col>
        <Col
          sm="12"
          md={{
            size: 10,
          }}
          className="my-2"
        >
          <div>
            <img
              src={a_giving1}
              loading="lazy"
              sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 991px) 728px, 940px"
              alt="Two children play with a ball."
            />
          </div>
        </Col>
        <Col
          sm="12"
          md={{
            size: 10,
          }}
          className="my-2"
        ></Col>
      </Row>
    </Container>
  );
};

export default Howyoucanhelp;
