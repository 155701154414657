import React from "react";
import { Row, Col } from "reactstrap";
import refugee from "../../assets/latest/64960bf1bc396805cc316c7f_ugandan-refugee.png";
import nigeriaStudent from "../../assets/latest/64960bf1da9de9bb4e172d56_students-in-nigeria.png";
import gambiaGirl from "../../assets/latest/64960bf1cf4e66e76f02cbf7_gambia-girls-united-club.png";

const Taxdeductible = () => {
  return (
    <Row>
      <Col
        sm="12"
        md={{
          offset: 2,
          size: 8,
        }}
      >
        <p className="hide-large d-sm-none">
          <strong>LASTING&nbsp;CHANGE</strong>
        </p>
        {/* <Row className="justify-content-center">
          <Col sm="8">
            <img
              src={Golf}
              loading="lazy"
              alt="A woman celebrates her Golf success at a Charity Fundraiser coming up July 20 2024 8am"
              className="image-53"
            />
          </Col>
        </Row> */}
        <Row className="d-flex justify-content-center flex-wrap mt-5 my-4">
          <Col sm="4" style={{ width: "37%" }}>
            <img
              src={refugee}
              loading="lazy"
              alt="A young Ugandan refugee"
              className="image-53"
            />
          </Col>
          <Col sm="4">
            <img
              src={nigeriaStudent}
              loading="lazy"
              sizes="(max-width: 479px) 92vw, 300px"
              alt="Students helping people in Nigeria"
              className="image-53"
            />
          </Col>
        </Row>
        <Row className="d-flex justify-content-center flex-wrap my-2">
          <Col sm="4">
            <img
              src={gambiaGirl}
              alt="Members of a girls club in Gambia"
              className="image-53"
            />
          </Col>
        </Row>
      </Col>
      <Col
        sm="12"
        md={{
          offset: 2,
          size: 8,
        }}
        className="mt-5"
      >
        {/* <Row className="button_text" style={{ textAlign: "center" }}>
          <Col sm="9">
            <h3 className="ms-5 mt-2">
              <strong>MAKE A TAX DEDUCTIBLE CONTRIBUTION:</strong>
            </h3>
          </Col>
          <Col sm="2" md="2">
            <a
              href="/friends/how-you-can-help"
              className="button cc-contact-us"
            >
              <div>To Act</div>
            </a>
            <a href="/friends/how-you-can-help" className="button button-3">
              To Act
            </a>
          </Col>
        </Row> */}
      </Col>
    </Row>
  );
};

export default Taxdeductible;
