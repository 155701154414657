import React from "react";
import { Container, Row, Col, Card } from "reactstrap";
import "./style.css";
import nigeria_2023 from "../../assets/images/nigeria-2023.jpg";

import ImageGallery from "../helpers/ImageGallery";

const Index = () => {
  return (
    <Container>
      <Row className="justify-content-center">
        <Col
          sm="12"
          md={{
            size: 10,
          }}
        >
          <img
            src={nigeria_2023}
            loading="lazy"
            alt="Nigerian students on a project."
          />
          <Card>
            <p className="paragraph-52">
              These Nigerian students from Harvard International have reduced
              health disparities for millions of people without access to
              clinics. After a long day of free public service, they felt
              pleased that blood pressures in the village proved lower, after
              seeing high rates in the cities. Learn about the work of other
              global youth and read their stories on the map page.
            </p>
            <Row>
              <Col
                sm="4"
                className="d-flex justify-content-center align-items-center flex-wrap my-3"
              >
                <a href="/mission-and-vision" className="rounded-link w-button">
                  Mission and Vision
                </a>
              </Col>
              <Col
                sm="4"
                className="d-flex justify-content-center align-items-center flex-wrap my-3"
              >
                <a
                  href="/improving-the-world"
                  className="rounded-link w-button"
                >
                  Improving the World
                </a>
              </Col>
              <Col
                sm="4"
                className="d-flex justify-content-center align-items-center flex-wrap my-3"
              >
                <a href="/community-impact" className="rounded-link w-button">
                  Community Impact
                </a>
              </Col>
            </Row>
          </Card>
          <div className="multi-column collage_gallery mt-4">
            <ImageGallery />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Index;
