import React from "react";
import BobHopperAndFriends from "../../assets/images/BobHopperAndFriends.png";
import { Row, Col, List, Container } from "reactstrap";
const History = () => {
  return (
    <Container>
      <Row className="line_height">
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10,
          }}
        >
          <h1 className="mt-4 mb-4 fs-1 fw-bold">History</h1>
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10,
          }}
        >
          <Row className="my-4">
            <Col sm="6">
              <p>Out of trauma comes innovation and collaboration.</p>
              <p>
                Civil unrest plagued 57 city blocks of urban Los Angeles from
                April 29 – May 2, 1992. The fires left an ember amid the ashes,
                however. That spark ignited a widespread desire among
                professionals and volunteers alike to help the traumatized
                children find healing and grow into solution-givers for their
                community.
              </p>
              <p className="mb-0">
                People from all walks of life reached across the divide to teach
                the children to trust once again. Meeting in space donated by
                the Los Angeles Baha’i Center, service groups representing
                various ages, causes and ethnicities each offered to spend one
                afternoon each week with the students.
              </p>
            </Col>
            <Col sm="6">
              <img
                src={BobHopperAndFriends}
                loading="lazy"
                alt="A man is crowded by several children in celebration of painting neighborhood walls"
                style={{ width: "100%" }}
              />
              <p>
                <strong>
                  Volunteer Bob Hopper helped students paint flowers on burned
                  down buildings.
                </strong>
              </p>
            </Col>
            <Col sm="12" className="my-2">
              <p>
                It soon became clear that shared hobbies, homework help,
                superficial character education lessons, and fractured volunteer
                schedules did not go far enough to unleash the potential of
                these young change agents. They needed systematic opportunities
                to discover that their learning, their character, and their
                talents could uplift, inform and advocate change—that they could
                generate well-being and advance a more unified community.
              </p>
              <p className="mb-0">
                The pilot site thus became a laboratory for discovering how
                service to the human family can influence learning. The
                integrated educational model called Full-Circle Learning emerged
                from the process and mushroomed into a global movement, creating
                bonds among diverse learners and broader community members
                across the world.
              </p>
            </Col>
            <Col sm="12" className="my-2">
              Educators took note as the learners infused habits-of-heart,
              curiosity, creativity, and conflict resolution into transformative
              community service projects. Long-term data showed that their
              skills and motivation advanced far beyond that of peers outside
              the program. Even the conflict resolution techniques built into
              each unit deepened to include guided imagery and self-reflection
              and empathy. As teachers used effective strategies to help them
              &ZeroWidthSpace;addressed relevant sustainable development goals,
              the students gained a new sense of purpose.
            </Col>
            <Col sm="12" className="my-2">
              In the early years, the students began to develop problem-solving
              partnerships with other schools in developing nations. They
              collaborated on themes and essential service across borders. These
              wisdom exchanges broadened their perspectives and their capacity
              to layer wisdom and achieve solutions to dilemmas with a local and
              global context. Students experienced a growing connection to their
              human family and advanced in grade equivalency as well as
              resiliency.
            </Col>
            <Col sm="12" className="my-2">
              News of the effectiveness of the programs quickly spread.
              Educators requested Full-Circle Learning training and support. The
              organization developed a series of books, workshops, CDs and
              college courses to help guide the process.
            </Col>
            <Col sm="12" className="my-2">
              By then, teachers in diverse settings had embraced a common
              vision. Their learners thrived as they reached out to vulnerable
              populations, addressing eldercare, equity, hunger, health and
              homelessness. &nbsp;What began as a grassroots project inspired
              thousands of lives and nurtured a new generation of humanitarian
              change agents.
            </Col>
            <Col sm="12" className="my-2">
              The model was adaptable for classrooms from preschool through
              college. Learners reshaped their communities by applying a
              unit-based habit-of-heart such as respect, kindness, integrity,
              advocacy, to the new challenge embedded into each learning unit.
            </Col>
            <Col sm="12" className="my-2">
              These historic highlights followed:
            </Col>
            <Col sm="12" className="my-4">
              <List>
                <li>
                  Origin: The early version of the educational model and NGO
                  began to develop in 1992.
                </li>
                <li>
                  Research by 1998: independent assessments showed that 80% of
                  students increased their grade equivalency academic rankings.
                  100% of parents also reported enhanced social cohesion skills.
                </li>
                <li>
                  Translations by 2002 (year 10): Teachers in ten regions had
                  received training. The curriculum had been translated into
                  Spanish and Chinese.
                </li>
                <li>
                  Awards: By 2004, the Academy for Educational Development in
                  Washington, D.C. had granted its Promising Practices award.
                  The Los Angeles County Human Relations Commission honored the
                  organization with a John Anson Ford Award. The Ventura
                  <br />
                  County Baha’is offered their Human Rights Award.
                </li>
                <li>Growth </li>
                <li className="ms-5">
                  2002 (year 10): 10 locations had received training and the
                  curriculum had been
                  <br />
                  translated into Spanish and Chinese.
                </li>
                <li className="ms-5">
                  2007 (year 15): The organization had served 75 schools.
                </li>
                <li className="ms-5">
                  2010 (year 18): Training programs extended from the Americas
                  to Africa to Asia. All the new teachers across China’s
                  Zhejiang Province learned the model. Chinese book series are
                  translated at the university. Spanish and Tamil translations
                  are also in place for Latin American and Indian students.
                </li>
                <li className="ms-5">
                  2012 (year 20): 105 locations exist, with additional projects
                  in Melanesia, the Middle East, and Europe. French translations
                  are added.
                </li>
                <li className="ms-5">
                  2016 (year 24): Approximately 30,000 teachers in 27 nations
                  participate.
                </li>
                <li className="ms-5">
                  2020 (year 28): Services expand in Sub-Saharan Africa.
                  Full-Circle Learning offers capacity building (onsite and
                  online learning), community impact grants, scholarships, and
                  humanitarian aid.
                </li>
              </List>
              <p>
                2024 (year 33) Full-Circle Learning had touched the lives of
                people in 40 countries over time, serving more than 2 million
                per year by its third-century mark. The Africa Continental
                Committee and global advisory board assisted in building the
                capacity of a dozen new FCL civil society organizations or NGOs.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
export default History;
