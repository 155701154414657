import React from "react";
import groupsun from "../../assets/images/groupsun-p-1080.png";
import { Row, Col, Container } from "reactstrap";

const HowWeOperate = () => {
  return (
    <Container>
      <Row>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10,
          }}
        >
          <h1 className="mt-4 mb-4 fs-1 fw-bold">How We Operate</h1>
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10,
          }}
        >
          <div className="d-flex justify-content-center">
            <img
              src={groupsun}
              loading="lazy"
              sizes="(max-width: 767px) 94vw, (max-width: 991px) 698px, 910px"
              alt="A group of students pose for a group."
            />
          </div>
          <p>
            Full-Circle Learning (FCL-GAB), is the global advisory board for
            Full-Circle Learning non-governmental organizations (NGOs) and civil
            society organizations (CSOs) around the world.
          </p>
          <p>
            By the time of this transition, Full-Circle Learning generated
            community transformation stories in forty countries over a third of
            a century. Its 12 board members each offered decades of voluntary
            service. More than two million people in 12 countries also benefited
            financially in the transitional year, receiving funds for capacity
            building, community impact grants, and scholarships.
          </p>
          <p>
            Philanthropic (corporate and foundation) grants, individual
            contributions, and in-kind goods and services provided the means for
            this organic, systematic growth. Through our commitment to global
            connection paired with local autonomy, each country has now
            developed its own board leadership capacity and path to
            sustainability.
          </p>
          <p>
            Meanwhile, GuideStar, the research resource for grant providers,
            accredited Full-Circle Learning with the platinum star, the highest
            rating for transparency and financial fitness. Great Nonprofits
            (#GreatNP2023) gave Full-Circle Learning its highest rating for
            participant reviews. Ongoing research and wisdom exchange helped
            each country send and receive insights about how to apply the FCL
            educational model to benefit the well- being of the human family and
            other living beings.
          </p>
          <p>
            Each country’s schools continue to apply student learning to address
            sustainability goals such as food insecurity, health disparities,
            climate change impacts, elder care, gender equity, conflict
            resolution, and peace.
          </p>
          <p>
            Tax-deductible FCL-earmarked contributions may now be received
            quarterly through our fiscal agent, the Mahmoudi Foundation, whose
            donation information is linked to this website.
          </p>
        </Col>
      </Row>
    </Container>
  );
};
export default HowWeOperate;
