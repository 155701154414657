import React from "react";
import { Row, Col, List, Container } from "reactstrap";

import logo from "../../assets/images/logo.png";
import f_image2 from "../../assets/images/f_image2-p-500.jpeg";
import Alagie from "../../assets/images/Alagie.jpg";
import Eric_explaining from "../../assets/images/Eric_explaining bridge-p-500.jpg";
import Nigeria_Graduates from "../../assets/images/Nigeria-Graduates 2022.jpg";
import Funmi_with_an_elder from "../../assets/images/Funmi_with_an_elder.jpg";
import Chadean_Facilitators from "../../assets/images/Chadean_Facilitators.jpg";
import Chad_growing from "../../assets/images/Chad_growing food for inmate project.jpg";
import Fiston_with_Soma from "../../assets/images/Fiston+with_Soma Soma refugee camp workers-p-500.jpg";
import MaureenAndRene from "../../assets/images/MaureenAndRene.png";
import SanaGroup from "../../assets/images/SanaGroup.png";
import FaribaGroup from "../../assets/images/FaribaGroup-p-1080.png";
import MarisolRexachHoB from "../../assets/images/MarisolRexachHoB.png";
import SugeyLopezStephanieOchoa from "../../assets/images/SugeyLopezStephanieOchoa-p-1080.png";
import FaribaAndStudent from "../../assets/images/FaribaAndStudent.png";
import BoardMemberGroup from "../../assets/images/BoardMemberGroup-p-1080.png";
import e_Jin from "../../assets/images/e_Jin-Mei-Gan-p-800.png";
import ZambiaVisit from "../../assets/images/ZambiaVisit-p-500.png";
import paths from "../../assets/images/paths.png";
import TeresaBoli from "../../assets/images/TeresaBoli.png";
import e_SimadjiJustin from "../../assets/images/e_SimadjiJustin.png";
import c181_c5 from "../../assets/images/c181_c5-p-1080.png";
import ChinaFCLMetDean from "../../assets/images/ChinaFCLMetDean-p-1080.png";
import ChinaGreentown from "../../assets/images/ChinaGreentown-p-1080.png";
import EricMuleya from "../../assets/images/EricMuleya.png";
import LindseySchuman from "../../assets/images/LindseySchuman.png";
import cbbe_GreentownMrWang from "../../assets/images/cbbe_GreentownMrWang.png";
import TeresaChina from "../../assets/images/TeresaChina.png";
import TeachersClass from "../../assets/images/TeachersClass.png";
import BigHeartShapeLIttleMinds from "../../assets/images/BigHeartShapeLIttleMinds-p-500.png";

const WhoWeAre = () => {
  return (
    <Container>
      <Row className="lineheight">
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10,
          }}
        >
          <h1 className="mt-4 mb-4 fs-1 fw-bold">Who We Are</h1>
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10,
          }}
        >
          <h2 className="secondary sub-secondary">About Our Purpose</h2>
          <p>
            Full-Circle Learning, as an organization, exists to help young
            people embrace their role as society’s humanitarians and change
            agents.
          </p>
          <p>
            Educators from almost every continent have discovered their capacity
            to transform their communities through Full-Circle Learning teacher
            training programs and direct service to students and schools.
          </p>
          <p>
            Full-Circle Learning’s team of pro bono service providers includes
            board members, community members, parents, and organizations –all
            supporting teachers in their noble role of molding a generation of
            world citizens who apply their skills to serve the human family.
          </p>
          <p>
            All who embrace a common vision have come together over the years to
            collaborate in wisdom exchanges and to practice strategies of truly
            transformational education. For a basic grasp of the philosophy and
            pedagogy of Full-Circle Learning, see the statements that follow:
            Philosophy Statement and Twenty Marks of a Full-Circle Learning
            School.
          </p>
          <div>
            <div className="d-flex justify-content-center">
              <img
                src={logo}
                loading="lazy"
                alt="Brand for Full-Circle Learning"
              />
            </div>
          </div>
        </Col>

        <Col
          sm="12"
          md={{
            size: 9,
          }}
          className="whowecss"
        >
          <h2 className="heading-30 mt-3">
            <strong>Full-Circle Learning Board Philosophy Statement</strong>
          </h2>
          <p className="paragraph-21">
            This philosophy statement guides the way the organization strives to
            conduct collaborative relationships and should also influence its
            approach to teaching students about global project development,
            capacity building, and learning partnerships that promote
            sustainable development.
          </p>
          <p>The Full-Circle Learning organization recognizes the value of:</p>
          <List>
            <li>
              The basic human right of all people to an integrated education
              with a world-embracing vision.
            </li>
            <li>
              The nobility of people of all cultural and ethnic backgrounds.
            </li>
            <li>Increasing socio-economic equity among all peoples.</li>
            <li>The equality of the station of women and men.</li>
            <li>
              The importance of developing the qualities of respectful
              communication, integrity and sacrifice in the future leaders who
              will address the challenges that face the world.
            </li>
            <li>
              The relationship of the personal character development, cognitive
              development and creative development of the individual and the
              social development of a whole community.
            </li>
            <li>
              The setting aside of differences to embrace the common vision of
              helping to carry forward an ever-advancing civilization through
              the love, nurturing and careful education of each new generation
              of world citizens.
            </li>
          </List>
          <p>
            To help students in their formative years enjoy purposeful lives
            today and a vision for the future, and to assist communities in
            facilitating this process, the organization shall encourage capacity
            building in the communities it serves:
          </p>
          <p>In general, by:</p>
          <List>
            <li>
              Responding to community needs as its primary focus and serving as
              a resource or a partner to agencies, institutions, community
              groups, learning organizations or sponsors in communities
              requesting assistance.
            </li>
          </List>
        </Col>
        <Col
          sm="12"
          md={{
            offset: 2,
            size: 8,
          }}
        >
          <h2 className="font-weight-bold my-3">
            Twenty Marks of a Full-Circle Learning School
          </h2>
          <List tag="ol">
            <li>Learners see giving as the purpose of learning.</li>
            <li>
              Learners feel motivated by their empathic membership in one human
              family.
            </li>
            <li>
              These commitments inspire peak experiences and creative,
              compassionate action toward members of an ever-widening circle of
              humans and living things.
            </li>
            <li>
              Expressions of process-based learning and critical thinking
              integrate all academic content areas and art forms, as learners
              strive to uplift, advocate, remedy, build, advance solutions and
              otherwise contribute to the well-being of others.
            </li>
            <li>
              Service to humanity finds parallels in the world community,
              through global wisdom exchange projects linked to local
              transformation projects.
            </li>
            <li>
              One specific character trait brings thematic continuity to all the
              subjects within a learning unit.
            </li>
            <li>
              Each new learning unit contributes to an overall year-long
              identity, specific to that grade level. (For example, four
              successive traits may occur over the year for the Humanitarians
              class.)
            </li>
            <li>
              Teachers customize learning units for regional education standards
              and for the community needs they target through projects.
            </li>
            <li>
              A student absorbs 60 or more habits-of-heart over a school career,
              one at a time.
            </li>
            <li>
              Each habit-of-heart becomes the theme for an integrative unit plan
              with service outcomes, based on the teacher’s integration of the
              universal13-S steps (incorporating researched, scaffolded steps).
            </li>
            <li>
              In their service-learning actions, learners frequently define
              teaching as service.
            </li>
            <li>
              Deliberate classroom management strategies and teaching methods:
              1) nurture altruistic identities, 2) promote self-mastery and 3)
              engender a collaborative peer culture among students and across
              classrooms.
            </li>
            <li>
              Students receive opportunities to appreciate differences, to bond
              with positive role models and to problem solve through hardship
              (challenging scenarios).
            </li>
            <li>
              Within each learning unit, conflict resolution applications help
              learners understand the need for inner growth and outward
              collaboration in personal, local, and world-stage dilemmas.
            </li>
            <li>
              Students experience daily opportunities to see the good in others.
            </li>
            <li>
              Students receive opportunities to reinforce habits-of-heart at
              home, to teach and learn from family members, and to honor the
              habits-of-heart in family members.
            </li>
            <li>
              Broad opportunities, over time, present a chance to engage with
              adult role models through community transformation and
              service-learning field trips, helping learners develop multiple
              strengths, entrepreneurial skills and options for civic-minded
              career paths.
            </li>
            <li>
              Educators bond, train and plan based on a shared local vision,
              foreseeing the end results of their planning from the start.
            </li>
            <li>
              Schools recognize the nobility of each individual and the equality
              of women, men and people of all cultural, ethnic and national
              backgrounds and belief systems.
            </li>
            <li>
              Teachers express their own nobility and sense of purpose as they
              foster a generation of change agents and humanitarians.
            </li>
          </List>
          <div className="d-flex justify-content-center">
            <img
              src={f_image2}
              loading="lazy"
              width="320"
              height="400"
              alt="Woman with t-shirt that reads She believed she could change the world so she became a teacher"
              sizes="(max-width: 479px) 100vw, 320px"
            />
          </div>
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10,
          }}
          className="my-3"
        >
          <h2 className="my-3">
            <strong>Primary Corporate and Foundation Donors </strong>(2022 and
            Beyond)
          </h2>
          <div className="d-flex justify-content-center">
            <List type="unstyled">
              <li>John Templeton Foundation</li>
              <li>MovieBox Art</li>
              <li>EDI Health Group</li>
              <li>Bregal Sagemount Corporation</li>
              <li>Mona Foundation</li>
              <li>One Planet Ops Inc.</li>
              <li>The Douglas Family Legacy Grant</li>
              <li>Mahmoudi Family Trust</li>
              <li>455 Foundation</li>
              <li>Nevada County Climate Action Now</li>
              <li>Sierra Foothills Audubon</li>
              <li>Bessie Minor Swift Foundation</li>
              <li>Amazon Smile Foundation</li>
              <li>Network for Good</li>
              <li>Rose Foundation</li>
              <li>Aspen Environmental</li>
            </List>
          </div>
          <p>
            For documentation of participating schools and sites around the
            world, see the{" "}
            <a href="/" className="link-7 inquires">
              Map
            </a>
            .
          </p>
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10,
          }}
          className="my-3"
        >
          <div>
            <img
              src={Alagie}
              loading="lazy"
              sizes="(max-width: 767px) 100vw, (max-width: 991px) 728px, 940px"
              alt="Alagie"
              style={{ width: "100%" }}
            />
          </div>
          <div className="">
            <p className="paragraph-48 mt-2">
              Alagie NDow, lead facilitator for the Gambia, reviewed his
              materials during a Full-Circle Learning training course.
            </p>
          </div>

          <div>
            <img
              src={Eric_explaining}
              loading="lazy"
              alt="Eric explaining bridge"
              sizes="(max-width: 479px) 100vw, 300px"
              style={{ width: "100%" }}
            />
          </div>
          <div className="mb-0">
            <p className="paragraph-48 mt-2">
              Eric Muleya Lead facilitator for Zambia, explained the conflict
              bridge to Zambian educators.
            </p>
          </div>

          <div>
            <img
              src={Nigeria_Graduates}
              loading="lazy"
              alt="Nigeria graduates"
              sizes="(max-width: 767px) 100vw, (max-width: 991px) 728px, 940px"
              style={{ width: "100%" }}
            />
          </div>
          <div className="mb-0">
            <p className="paragraph-48 mt-2">
              Harry Kennedy, Nigerian FCL Facilitator, generated community
              impact among many thousands with his health disparities team, some
              of whom appear here as new graduates of Harvard International
              School.
            </p>
          </div>

          <div>
            <img
              src={Funmi_with_an_elder}
              loading="lazy"
              alt="Funmi with an elder"
              sizes="(max-width: 767px) 100vw, (max-width: 991px) 728px, 900px"
            />
          </div>
          <div className="mb-0">
            <p className="paragraph-48 mt-2">
              Funmi Aberejesu, a Nigerian facilitator from the Osun state,
              gifted an elder honored in the students’ Batik project.
            </p>
          </div>
          <div>
            <img
              src={Chadean_Facilitators}
              loading="lazy"
              alt="Mabel on Autism"
              sizes="(max-width: 720px) 100vw, 720px"
              style={{ width: "100%" }}
            />
          </div>
          <div className="mb-0">
            <p className="paragraph-48 mt-2">
              Simadji Justin and Pacifique NDouba serve as Chadean Facilitators.
              They met here with other educational leaders.
            </p>
          </div>

          <div>
            <img
              src={Chad_growing}
              loading="lazy"
              alt="Growing food for inmates"
              sizes="(max-width: 767px) 100vw, (max-width: 991px) 728px, 940px"
              style={{ width: "100%" }}
            />
          </div>
          <div className="mb-0">
            <p className="paragraph-48 mt-2">
              Layla McBridge, of Chad, led students in delivering their
              vegetables in a food security program for inmates.
            </p>
          </div>
          <div>
            <img
              src={Fiston_with_Soma}
              loading="lazy"
              alt="Fiston with Soma Soma"
              sizes="(max-width: 479px) 100vw, 300px"
              style={{ width: "100%" }}
            />
          </div>
          <div className="mb-0">
            <p className="paragraph-48 mt-2">
              Fiston Muganda, lead facilitator for Uganda, has trained NGO
              workers, teachers and children in and around the Nakivale Refugee
              Camp.
            </p>
          </div>
        </Col>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10,
          }}
          className="my-3"
        >
          <Row className="my-2">
            <Col sm="6">
              <img
                src={MaureenAndRene}
                loading="lazy"
                alt="Maureen and Rene on the street in Spain."
              />
            </Col>
            <Col sm="6" className="d-flex flex-column justify-content-between">
              <p>
                Left, Senior Programs Officer and board member Maureen Mungai
                welcomed board treasurer Rene Sprattling in Marbella Spain, the
                headquarters of Full-Circle Learning Europa.
              </p>
            </Col>

            <Col sm="12">
              <p>
                Below, Operations Director and board member Sana Moussavi met
                with students whose teachers attended a training session
                sponsored by the Lesotho Curriculum Development Department.
              </p>
              <img
                src={SanaGroup}
                loading="lazy"
                sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 698px, 910px"
                alt="Sana kneels in front of children grouped together for."
              />
              <p className="paragraph-24">
                Below, board vice-president Fariba Mahjour championed a student
                presentation on gender equity and violence prevention in
                Monrovia Liberia.
              </p>
              <img
                src={FaribaGroup}
                loading="lazy"
                sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 698px, 910px"
                alt="Fariba outside with fellow teachers and others."
                className="image-42"
              />
            </Col>
            <Col sm="9" className="my-4">
              <p>
                Below, a joint fundraising event at the Los Angeles House of
                Blues included a speech by FCL board secretary Dr. Marisol
                Rexach, who teaches at Chapman University and University of
                California and also serves as Santa Ana School District Teacher
                Development Director.
              </p>
              <img
                src={MarisolRexachHoB}
                loading="lazy"
                sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 698px, 910px"
                alt="Marisol on stage at the house of blues."
              />
            </Col>
          </Row>
          <Row className="my-3">
            <Col sm="8">
              <img
                src={SugeyLopezStephanieOchoa}
                loading="lazy"
                sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 698px, 910px"
                alt="Sugey Lopez stands with Stephanie Ochoa outside."
              />
            </Col>
            <Col sm="4" className="d-flex align-items-center">
              <p>
                Sugey Lopez (the first site director at Rancho Sespe/Piru FCL
                programs) and her predecessor, Stephanie Ochoa, greeted families
                at a summer Mastery Ceremony. Sugey’s work caught the attention
                of district officials, who hired her to direct district-wide
                preschools, impressed with the accomplishments of secondary
                students who grew up in Full-Circle Learning programs.
              </p>
            </Col>
          </Row>
          <Row className="my-4">
            <Col sm="4">
              <img
                src={FaribaAndStudent}
                loading="lazy"
                sizes="(max-width: 479px) 100vw, (max-width: 647px) 95vw, 615px"
                alt="Fariba smiles with a young student."
              />
            </Col>
            <Col sm="8" className="d-flex align-items-center">
              <p>
                Fariba Mahjour escorted Tarzana Elementary School students on a
                field trip to honor biomedical researchers at Phenomenex for
                displaying the habit of altruism.
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm="12" className="my-2">
              <img
                src={BoardMemberGroup}
                loading="lazy"
                sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 698px, 910px"
                alt="A group of board members outside for a."
              />
            </Col>
            <Col sm="12">
              <p>
                Board members Lily Ning (upper left) and Rene Sprattling
                (center, in blue) posed with teacher Katie (Smith) Wada, online
                editor Daven Mathias and participants of the 2018 Climate Change
                Agents Camp.
              </p>
            </Col>
            <Col sm="12">
              <p>
                Below, left: Dr. Jin-mei Gan (left) instructed the teachers of
                Zhejiang Province, China, in the Full-Circle Learning approach
                that guided their development for more than a decade.
              </p>
            </Col>
            <Col sm="12">
              <p>
                Right: A visit to Zambia’s Cry School included visits with
                children and parents and and a service project for seniors.
              </p>
            </Col>
            <Col sm="12" className="d-flex justify-content-center">
              <div>
                <img
                  src={e_Jin}
                  loading="lazy"
                  sizes="(max-width: 479px) 100vw, (max-width: 767px) 53vw, (max-width: 991px) 388.9140625px, 507.03125px"
                  alt="Jin Mei Gan and Teresa Langness"
                />
              </div>
              <div>
                <img
                  src={ZambiaVisit}
                  loading="lazy"
                  sizes="(max-width: 479px) 100vw, (max-width: 767px) 42vw, (max-width: 991px) 309.0859375px, 402.96875px"
                  alt="Teresa in Zimbabwe with a children's class."
                />
              </div>
            </Col>
          </Row>

          <Row className="my-3">
            <Col sm="6">
              <img
                src={paths}
                loading="lazy"
                sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 698px, 732px"
                alt="Sana sits with a child for the."
                style={{ width: "100%" }}
              />
            </Col>
            <Col sm="6">
              <div>
                <p>
                  Left, Operations Director Sana Moussavi visited with a girl in
                  Mokhotlong, Lesothoto.
                </p>
                <p>
                  Below, Papua New Guinean Liaison Teresa Boli (left)
                  accompanied dancers on an Independence Day performance.
                </p>
                <p>
                  Bottom, Chadian FCL facilitator Simadji Justin paid a site
                  visit to a classroom teacher and students.
                </p>
              </div>
              <div>
                <img
                  src={TeresaBoli}
                  loading="lazy"
                  alt="People in traditional Papua New Guinean dress."
                />
              </div>
            </Col>
            <Col sm="12" className="my-2">
              <img
                src={e_SimadjiJustin}
                loading="lazy"
                sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 698px, 910px"
                alt="A class of students in a room with their two teachers."
              />
            </Col>
          </Row>

          <Row>
            <Col sm="12">
              <p>
                Below, Ghanaian teachers from multiple schools stopped for a
                photo during a training session.
              </p>
            </Col>
            <img
              src={c181_c5}
              loading="lazy"
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 698px, 910px"
              alt="A large group of teachers arrange themselves for a."
            />
          </Row>

          <Row className="my-3">
            <Col sm="12">
              <img
                src={ChinaFCLMetDean}
                loading="lazy"
                sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 698px, 910px"
                alt="Teresa with editors of Chinese Full-circle learning materials"
              />
            </Col>
            <Col sm="6" className="my-3">
              <p>
                Above, editors of Chinese Full-Circle Learning materials met
                with Dean Jinliang Qin for a special presentation, “The Most
                Important Day of a Teacher’s Life.” Right, the management team
                of Greentown Education System gathered on the campus of their
                main high school in Hangzhou, China.performance.
              </p>
            </Col>
            <Col sm="6" className="my-3">
              <img
                src={ChinaGreentown}
                loading="lazy"
                sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 698px, 910px"
                alt="Greentown Education Management team standing with Teresa."
              />
            </Col>
          </Row>

          <Row className="my-3">
            <Col sm="12">
              <img
                src={EricMuleya}
                loading="lazy"
                sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 698px, 910px"
                alt=" Eric Muleya teaching teachers in Zambia"
              />
            </Col>
            <Col sm="12" className="my-2">
              <p>
                Above: Zambia’s Eric Muleya helped teachers practice conflict
                resolution strategies to use in the classroom.
              </p>
            </Col>
            <Row>
              <Col sm="6">
                <img
                  src={LindseySchuman}
                  loading="lazy"
                  sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 698px, 910px"
                  alt="Lindsey Schurman in Liberia"
                />
              </Col>
              <Col sm="6" className="d-flex align-items-center">
                <p>
                  Left: Board member Lindsey Schurman spoke about the role of
                  EHG in supporting Full-Circle Learning programs, at a 2016{" "}
                  <em>Education as Transformation </em>conference hosted by FCL
                  Liberia.
                </p>
              </Col>
            </Row>
          </Row>

          <Row>
            <Col sm="12">
              <p>
                Left: Mr. Wang, General Manager of Greentown Education, modeled
                his gift, a scarf with translations of “love” in many languages,
                designed by board member Zack Dafaallah. Right: A keynote speech
                in the African Union building helped One Planet School
                commemorate its tenth anniversary.
              </p>
            </Col>
            <Col sm="6">
              <img
                src={cbbe_GreentownMrWang}
                loading="lazy"
                alt="Mr. Wang, general manager of Greentown, smiling at the camera."
              />
            </Col>
            <Col sm="6">
              <img
                src={TeresaChina}
                loading="lazy"
                sizes="(max-width: 479px) 100vw, (max-width: 555px) 94vw, 522px"
                alt="Teresa Langness in China."
              />
            </Col>
            <Row>
              <Col
                sm="6"
                className="d-flex flex-column justify-content-between"
              >
                <p>
                  Right: Dr. Rexach’s class of future teachers at Chapman
                  experimented with Full-Circle Learning’s “Sense It Step.”
                </p>
                <p>
                  Below: Piru’s Anna Banda received the gift of a T-shirt from a
                  grateful parent.
                </p>
              </Col>
              <Col sm="6">
                <img
                  src={TeachersClass}
                  loading="lazy"
                  sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 698px, 866px"
                  alt="Teachers in class participating in FCL project."
                />
              </Col>
            </Row>
          </Row>
          <Row>
            <Col
              sm="12"
              md={{
                offset: 3,
                size: 6,
              }}
              className="my-4"
            >
              <img
                src={BigHeartShapeLIttleMinds}
                loading="lazy"
                sizes=""
                alt="A woman holds up a t-shirt that says 'It takes a big heart to to help shape little minds.'"
                className="image-27"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
export default WhoWeAre;
