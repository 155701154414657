import React from "react";
import { Row, Col, Card, Container } from "reactstrap";
const CommunityImpact = () => {
  return (
    <Container style={{ height: "50dvh" }}>
      <Row className="h-100">
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10,
          }}
          className="h-100"
        >
          <Card className="h-100">
            <h1>Community Impact</h1>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://fullcirclelearningassets.s3.amazonaws.com/FCL-Community-Impact-Report-2024.pdf"
              className="inquires"
            >
              2024 Annual Report
            </a>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
export default CommunityImpact;
