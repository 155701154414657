import React from "react";
import { Row, Col, Container } from "reactstrap";
const Fclonline = () => {
  return (
    <Container>
      <Row>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10,
          }}
        >
          <div className="link_hov">
            <h2 id="top" className="fs-1">
              Why We Learn
            </h2>
            <p>
              An online <i>Education as Transformation</i> course
            </p>
            <p>
              The programs in this course begin with foundational principles and
              offer supporting practices. We highly encourage you to participate
              in the sessions in chronological order. Take time for the
              practicum activities, discussing outcomes with a colleague or
              cohort before moving on to the next session.
            </p>
          </div>
          <div>
            <p className="text-left mb-3">
              Click a session number to jump directly to that content:
            </p>
            <div className="d-flex justify-content-left gap-3 mb-4">
              <a href="#session1" className="text-decoration-none">
                1
              </a>
              <a href="#session2" className="text-decoration-none">
                2
              </a>
              <a href="#session3" className="text-decoration-none">
                3
              </a>
              <a href="#session4" className="text-decoration-none">
                4
              </a>
              <a href="#session5" className="text-decoration-none">
                5
              </a>
              <a href="#session6" className="text-decoration-none">
                6
              </a>
              <a href="#session7" className="text-decoration-none">
                7
              </a>
              <a href="#session8" className="text-decoration-none">
                8
              </a>
              <a href="#session9" className="text-decoration-none">
                9
              </a>
              <a href="#session10" className="text-decoration-none">
                10
              </a>
              <a href="#session11" className="text-decoration-none">
                11
              </a>
            </div>
          </div>
          <div className="link_hov">
            <h3 id="session1">
              Session 1: Welcome to Why We Learn{" "}
              <a
                href="#top"
                className="text-decoration-none"
                style={{ fontSize: "0.5em" }}
              >
                (Back to Top)
              </a>
            </h3>
            <p>
              Think about what motivates young people. Should we offer only a
              vision of what and how to learn - or also why to learn? Discover
              how this course can reward you with new insights on the topic.
            </p>
            <div className="mt-4">
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/HcUm6p-HNtE"
                title="Session 1: Welcome to Why We Learn"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div className="video-container">
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/SOY5d_UjUtU"
                title="Session 1: Welcome to Why We Learn"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <Row className="g-4">
              <Col sm="12" md="6" lg="4">
                <a
                  href="https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/1+Transcript+Welcome.pdf"
                  target="_blank"
                  rel="noreferrer"
                  className="d-block p-3 border rounded text-decoration-none"
                >
                  Transcript - Welcome to Why We Learn
                </a>
              </Col>
            </Row>
            <h3 id="session2" className="mt-4">
              Session 2: The Most Important Day of a Teacher's Life{" "}
              <a
                href="#top"
                className="text-decoration-none"
                style={{ fontSize: "0.5em" }}
              >
                (Back to Top)
              </a>
            </h3>
            <p>
              How can research-based pedagogy affect not just your job but your
              joy as an educator? Explore the relationship between peak
              experiences and purpose – for both teachers and learners.
            </p>
            <div className="video-container">
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/pZS6w_5NF00"
                title="Session 2"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <Row className="g-4">
              {[
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/2+Document+Your+Most+Important+Day.pdf",
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/2+The+Most+Important+Day+in+a+Teacher%E2%80%99s+Life.pptx",
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/2+Transcript+-+The+Most+Important+Day+in+a+Teacher's+Life.pdf",
              ].map((url, i) => {
                const title = url
                  .split("/")
                  .pop()
                  .replaceAll("+", " ")
                  .replaceAll("%", " ")
                  .replace(".pdf", "")
                  .replace(/2\s*/g, "")
                  .replace(/E80\s*/g, "")
                  .replace(/ 99\s*/g, "'");
                return (
                  <Col sm="12" md="6" lg="4" key={i}>
                    <a
                      href={url}
                      target="_blank"
                      rel="noreferrer"
                      className="d-block p-3 border rounded text-decoration-none"
                    >
                      {title}
                    </a>
                  </Col>
                );
              })}
            </Row>
            <h3 id="session3" className="mt-4">
              Session 3: Scaffolded Learning Units{" "}
              <a
                href="#top"
                className="text-decoration-none"
                style={{ fontSize: "0.5em" }}
              >
                (Back to Top)
              </a>
            </h3>
            <p>
              An educator must envision the end before the beginning (i.e., must
              visualize the desired outcome in order to create a plan that
              achieves it). However, the order of the teacher's planning may
              differ from the order in which the student best experiences the
              learning. In this session, you will practice a series of steps
              that trigger motivation for your whole class.
            </p>
            <div className="video-container">
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/cPZGdIeZkQU"
                title="Session 3"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <Row className="g-4">
              {[
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/3+13-S+Steps+-+Explanation.pdf",
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/3+13-S+Unit+Planner.pdf",
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/3+Activity+for+Scaffolding+a+Lesson+Plan+Unit.pdf",
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/3+Answer+Key+-+Review+Questions+for+Session+3.pdf",
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/3+Habits-of-Heart+Poster.pdf",
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/3+Purposeful+Planning+Toolkit.pdf",
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/3+Sample+13-S+-+Water+for+Life.pptx",
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/3+Scaffolded+Unit+Plan+Review+Questions.pdf",
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/3+SESSION+3+REVIEW+QUESTIONS.pdf",
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/3+Support+Materials+for+Scaffolding+Unit+Plans.pdf",
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/3+Transcript+-+Scaffolding+A+13-S+Unit.docx",
              ].map((url, i) => {
                const title = url
                  .split("/")
                  .pop()
                  .replaceAll("+", " ")
                  .replace(".pdf", "")
                  .replace(/3\s*/g, "");
                return (
                  <Col sm="12" md="6" lg="4" key={i}>
                    <a
                      href={url}
                      target="_blank"
                      rel="noreferrer"
                      className="d-block p-3 border rounded text-decoration-none"
                    >
                      {title}
                    </a>
                  </Col>
                );
              })}
            </Row>
            <h3 id="session4" className="mt-4">
              Session 4: Purposeful Classroom Management{" "}
              <a
                href="#top"
                className="text-decoration-none"
                style={{ fontSize: "0.5em" }}
              >
                (Back to Top)
              </a>
            </h3>
            <p>
              How do your classroom management goals align with your approach to
              curriculum and instruction? Learn strategies that promote
              classroom norms related to social cohesion, self-mastery, and a
              higher sense of purpose.
            </p>
            <div className="video-container">
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/w97jwBZ7MTo"
                title="Session 4"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <Row className="g-4">
              {[
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/4+Transcript+-+Purposeful+Classroom+Management.docx",
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/4+Support+Materials+-+Purposeful+Classroom+Management.pdf",
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/4+SESSION+4+REVIEW+QUESTIONS.pdf",
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/4+Habits-of-Heart+Poster.rtf",
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/4+Habits-of-Heart+Poster.pdf",
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/4+Habit+Homework+Sample.pdf",
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/4+ANSWER+KEY+for+Review+Questions+-+Session+4.pdf",
              ].map((url, i) => {
                const title = url
                  .split("/")
                  .pop()
                  .replaceAll("+", " ")
                  .replace(".pdf", "")
                  .replace(/4\s*/g, "");
                return (
                  <Col sm="12" md="6" lg="4" key={i}>
                    <a
                      href={url}
                      target="_blank"
                      rel="noreferrer"
                      className="d-block p-3 border rounded text-decoration-none"
                    >
                      {title}
                    </a>
                  </Col>
                );
              })}
            </Row>
            <h3 id="session5" className="mt-4">
              Session 5: Planning Tools{" "}
              <a
                href="#top"
                className="text-decoration-none"
                style={{ fontSize: "0.5em" }}
              >
                (Back to Top)
              </a>
            </h3>
            <p>
              Coherent planning tools can help you refine your approach to
              learning. Refer back to this session from the time you identify
              the link between community needs and academic goals until the time
              you assess your students' growth.
            </p>
            <div className="video-container">
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/YrefZbVzbG4"
                title="Session 5"
                allowFullScreen
              ></iframe>
            </div>
            <Row className="g-4">
              {[
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/5+Transcript+-+Helpful+Planning+Tools.docx",
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/5+SESSION+5+REVIEW+QUESTIONS.pdf",
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/5+Purposeful+Planning.pdf",
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/5+Purposeful+Planning+Toolkit.pdf",
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/5+ANSWER+KEY+-+Review+Questions+for+Session+5.pdf",
              ].map((url, i) => {
                const title = url
                  .split("/")
                  .pop()
                  .replaceAll("+", " ")
                  .replace(".pdf", "")
                  .replace(/5\s*/g, "");
                return (
                  <Col sm="12" md="6" lg="4" key={i}>
                    <a
                      href={url}
                      target="_blank"
                      rel="noreferrer"
                      className="d-block p-3 border rounded text-decoration-none"
                    >
                      {title}
                    </a>
                  </Col>
                );
              })}
            </Row>
            <h3 id="session6" className="mt-4">
              Session 6: Human Contact and Human Conflict{" "}
              <a
                href="#top"
                className="text-decoration-none"
                style={{ fontSize: "0.5em" }}
              >
                (Back to Top)
              </a>
            </h3>
            <p>
              Do relationships tend to enhance or limit learning within your
              school community? The strategies in this session contextualize
              both academic and authentic conflict resolution opportunities,
              leading to more effective communication, empathy, and problem
              solving.
            </p>
            <div className="video-container">
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/ggw6weeZA80"
                title="Session 6"
                allowFullScreen
              ></iframe>
            </div>
            <Row className="g-4">
              {[
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/6+Twenty+Marks+of+a+Full-Circle+Learning+School.pdf",
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/6+Transcript+-+Human+Conflict+%26+Human+Contact.docx",
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/6+SESSION+6+REVIEW+QUESTIONS.pdf",
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/6+Schoolwide+Rubric%2C+Condensed.pdf",
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/6+Human+Contact+and+Human+Conflict+.pdf",
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/6+Human+Contact+and+Human+Conflict+.pdf",
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/6+Answer+Key+-+Session+6+Review+Questions.pdf",
              ].map((url, i) => {
                const title = url
                  .split("/")
                  .pop()
                  .replaceAll("+", " ")
                  .replaceAll("%2C", " ")
                  .replaceAll("%2", " ")
                  .replace(".pdf", "")
                  .replace(/6\s*/g, "");
                return (
                  <Col sm="12" md="6" lg="4" key={i}>
                    <a
                      href={url}
                      target="_blank"
                      rel="noreferrer"
                      className="d-block p-3 border rounded text-decoration-none"
                    >
                      {title}
                    </a>
                  </Col>
                );
              })}
            </Row>
            <div className="mt-4">
              <h3 id="session7">
                Session 7: Educating the Next Generation of Climate Change
                Agents{" "}
                <a
                  href="#top"
                  className="text-decoration-none"
                  style={{ fontSize: "0.5em" }}
                >
                  (Back to Top)
                </a>
              </h3>
              <p>
                When asked about his vision of the future, an 8-year-old
                recently said, "I wonder whether it will be a healthy or
                unhealthy world, because of climate change." This session offers
                ideas for designing learning units that help each child apply
                their unique strengths to fulfill their hope for a healthy
                world.
              </p>
            </div>
            <div className="video-container">
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/YIgTuds4Ku0"
                title="Session 7"
                allowFullScreen
              ></iframe>
            </div>
            <Row className="g-4">
              {[
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/7+Transcript+-+Educating+the+Next+Generation+of+Climate+Change+Agents.pdf",
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/7+SESSION+7+REVIEW+QUESTIONS.pdf",
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/7+Sample+Unit+on+Climate+Change.pdf",
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/7+Sample+Classroom+Climate+Actions.pdf",
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/7+Idea+Bank+of+Climate+Change+Project+Activities.pdf",
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/7+Answer+Key+-+Session+7+Review+Questions.pdf",
              ].map((url, i) => {
                const title = url
                  .split("/")
                  .pop()
                  .replaceAll("+", " ")
                  .replace(".pdf", "")
                  .replace(/7\s*/g, "");
                return (
                  <Col sm="12" md="6" lg="4" key={i}>
                    <a
                      href={url}
                      target="_blank"
                      rel="noreferrer"
                      className="d-block p-3 border rounded text-decoration-none"
                    >
                      {title}
                    </a>
                  </Col>
                );
              })}
            </Row>
            <div className="mt-4">
              <h3 id="session8">
                Session 8: Technology and the Wisdom Exchange{" "}
                <a
                  href="#top"
                  className="text-decoration-none"
                  style={{ fontSize: "0.5em" }}
                >
                  (Back to Top)
                </a>
              </h3>
              <p>
                The increasing emphasis on technology in 21st century learning
                has challenged teachers everywhere to balance "access to
                information" with "access to wisdom." How can learners better
                evaluate the sources and context of media messages and become
                savvy to the enduring value of lived experience?
              </p>
            </div>
            <div className="video-container">
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/32SYwjpU2lA"
                title="Session 8"
                allowFullScreen
              ></iframe>
            </div>
            <Row className="g-4">
              {[
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/8+Transcript+-+Technology+and+the+Wisdom+Exchange.pdf",
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/8+Supporting+Actions+for+Technology+and+the+Wisdom+Exchange.pdf",
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/8+SESSION+8+REVIEW+QUESTIONS.pdf",
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/8+Matching+World+Community+Needs+and+Resources.pdf",
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/8+ICETI+2021+Conference+Paper+-++Utilizing+ICT+in+Six+African+Countries+in+Response+to+Covid-19.pdf",
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/8+Answer+Key+-+Review+Questions+for+Session+8.pdf",
              ].map((url, i) => {
                const title = url
                  .split("/")
                  .pop()
                  .replaceAll("+", " ")
                  .replace(".pdf", "")
                  .replace(/8\s*/g, "");
                return (
                  <Col sm="12" md="6" lg="4" key={i}>
                    <a
                      href={url}
                      target="_blank"
                      rel="noreferrer"
                      className="d-block p-3 border rounded text-decoration-none"
                    >
                      {title}
                    </a>
                  </Col>
                );
              })}
            </Row>
            <div className="mt-4">
              <h3 id="session9">
                Session 9: Loving the Exceptional Learner{" "}
                <a
                  href="#top"
                  className="text-decoration-none"
                  style={{ fontSize: "0.5em" }}
                >
                  (Back to Top)
                </a>
              </h3>
              <p>
                Every classroom includes students of varied learning styles,
                capacities, and challenges. How can the school honor each
                learner's best potential while helping the group practice
                supportive roles in a diverse society – in very practical ways?
              </p>
            </div>
            <div className="video-container">
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/-JZm5XHX-Mw"
                title="Session 9"
                allowFullScreen
              ></iframe>
            </div>
            <Row className="g-4">
              {[
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/9+Transcript+-+Loving+the+Exceptional+Learner.pdf",
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/9+Strategies+for+Helping+Students+in+the+Inclusive+Classroom.pdf",
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/9+SESSION+9+REVIEW+QUESTIONS.pdf",
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/9+Answer+Key+-+Review+Questions+for+Session+9.pdf",
              ].map((url, i) => {
                const title = url
                  .split("/")
                  .pop()
                  .replaceAll("+", " ")
                  .replace(".pdf", "")
                  .replace(/9\s*/g, "");
                return (
                  <Col sm="12" md="6" lg="4" key={i}>
                    <a
                      href={url}
                      target="_blank"
                      rel="noreferrer"
                      className="d-block p-3 border rounded text-decoration-none"
                    >
                      {title}
                    </a>
                  </Col>
                );
              })}
            </Row>
            <div className="mt-4">
              <h3 id="session10">
                Session 10: Wishing on a North Star{" "}
                <a
                  href="#top"
                  className="text-decoration-none"
                  style={{ fontSize: "0.5em" }}
                >
                  (Back to Top)
                </a>
              </h3>
              <p>
                Educators and learners alike long for meaningful lives. Inspire
                your students' altruistic instincts, passions, and peak
                experiences, to activate their highest capacity for improving
                well-being for their human and all living beings.
              </p>
            </div>
            <div className="video-container">
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/emd09-QKJY8"
                title="Session 10"
                allowFullScreen
              ></iframe>
            </div>
            <Row className="g-4">
              {[
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/10+Transcript+-+Wishing+on+a+North+Star.pdf",
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/10+Improving+Life+as+a+North+Star+for+Research+-+Langness.pdf",
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/10+Keep+your+North+Star+Visible.pdf",
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/10+ANSWER+KEY+-+Review+Questions+for+Session+10.pdf",
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/10+North+Star+Educators+-+Assess+Your+Planning+Process.pdf",
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/10+Recording+Thoughts+and+Wishes.pdf",
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/10+SESSION+10+REVIEW+QUESTIONS.pdf",
              ].map((url, i) => {
                const title = url
                  .split("/")
                  .pop()
                  .replaceAll("+", " ")
                  .replace(".pdf", "")
                  .replace(/10\s*/g, "");
                return (
                  <Col sm="12" md="6" lg="4" key={i}>
                    <a
                      href={url}
                      target="_blank"
                      rel="noreferrer"
                      className="d-block p-3 border rounded text-decoration-none"
                    >
                      {title}
                    </a>
                  </Col>
                );
              })}
            </Row>

            <h3 id="session11" className="mt-4 mb-3">
              Session 11: Next Steps and Certificate of Completion{" "}
              <a
                href="#top"
                className="text-decoration-none"
                style={{ fontSize: "0.5em" }}
              >
                (Back to Top)
              </a>
            </h3>
            <div className="video-container">
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/luNV2trZuI0"
                title="Session 11"
                allowFullScreen
              ></iframe>
            </div>
            <Row className="g-4">
              {[
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/11+Next+Steps.pdf",
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/11+Twenty+Marks+of+a+Full-Circle+Learning+School.pdf",
                "https://fullcirclelearningassets.s3.us-east-1.amazonaws.com/course/11+Certificate+of++Online+Course+Completion.pdf",
              ].map((url, i) => {
                const title = url
                  .split("/")
                  .pop()
                  .replaceAll("+", " ")
                  .replace(".pdf", "")
                  .replace(/11\s*/g, "");
                return (
                  <Col sm="12" md="6" lg="4" key={i}>
                    <a
                      href={url}
                      target="_blank"
                      rel="noreferrer"
                      className="d-block p-3 border rounded text-decoration-none"
                    >
                      {title}
                    </a>
                  </Col>
                );
              })}
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default Fclonline;
