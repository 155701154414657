import React from "react";
import { Row, Col, Card, Container } from "reactstrap";
import board from "../../assets/images/boardofFCLGAB.jpg";
const ImprovingTheWorld = () => {
  return (
    <Container>
      <Row>
        <Col
          sm="12"
          md={{
            offset: 1,
            size: 10,
          }}
        >
          <Card>
            <h1>Improving the World</h1>
            <p className="mt-3 line_height">
              <strong>
                Full-Circle Learning (FCL), having operated as an award-winning
                global 501 (c) 3 charitable organization for a third of a
                century, launched a plan for global growth in 2025 and beyond.
              </strong>
            </p>
            <p className="line_height">
              The many participating countries with registered Full-Circle
              Learning NGOs will directly manage their contributions, while the
              US organization will operate as Full-Circle Learning Global
              Advisory Board (FCL-GAB). We will continue to encourage each
              region in its path to education as transformation.
            </p>
            <p className="line_height">
              By integrating life skills, academics, local service, and global
              wisdom exchanges, they attach greater purpose to learning, to
              altruism, and to positive change. Their schools contextualize
              habit-of-heart themes through academic units that tackle poverty,
              health disparities, elder care, food security, peace, climate
              change adaptations — or whatever will enhance their local
              communities, create systemic transformation, strengthen students’
              skills and convictions, and uplift the human family.
            </p>
            <img src={board} alt="Global Advisory Board" />
            <p>Members of the global advisory board pictured:</p>
            <p>
              Upper Row, Left to Right: Baktash Aazami, Marisol Rexach, and
              Rodney Hume- Dawson. Lower Row, Left to Right: Lily Ning, Teresa
              Langness, and Fariba Mahjour. Members Not Pictured: Henry Wilson
              Lake, Rene Sprattling, Sana Moussavi, Taraneh Darabi, Maureen
              Mungai, and Zack Daafallah
            </p>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
export default ImprovingTheWorld;
